import "./style.css";
import React, {useState} from 'react';
import {useRef, useEffect} from 'react';
import Background from "../../components/AnimatedBackground";
import Buttons from "../../components/NextButton";
import {Link, useHistory} from "react-router-dom";
import Auth from "../../api/auth";
import {setAccessToken, setUserId} from "../../utils/auth";
import Swal from "sweetalert2";

const Login = () =>{
	let history = useHistory();
    const signUp = useRef(null);
    const signIn = useRef(null);
	const [email,setEmail] = useState("");
	const [password,setPass] = useState("");




    // const container = useRef(null);
    //  useEffect(() => {
    //     const signUpButton = signUp.current;
    //     const signInButton = signIn.current;
    //     const containers = container.current;
    //     signUpButton.addEventListener('click', () => {
    //         containers.classList.add("right-panel-active");
    //     });
	//
    //     signInButton.addEventListener('click', () => {
    //         containers.classList.remove("right-panel-active");
    //     });
	//
    //   }, []);

//     this.signUp = React.createRef();
//     this.signIn = React.createRef();
//     this.container = React.createRef();
	const LoginPass = (e) => {
		e.preventDefault()
		try {
			Auth.loginWithPassword(email, password).then((result) => {

				if (result.data.ok) {
					setAccessToken(result.data.data.access_token);
					setUserId(result.data.data)
					history.push("/dashboard");
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: result.data.message
					});
				}
			});
		} catch (error) {
			console.log(error);
			Swal.fire({
				icon: "error",
				title: "Oops...",
			});
		}
	}



    return(
        <>
            <Background/>
			<div className="login">
            <div class="container" id="containerLogin">
            	<div class="form-container sign-in-container">
            		<form onSubmit={LoginPass}>
            			<h1>Sign in</h1>
            			<span>use your account</span>
            			<input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)}/>
            			<input type="password" placeholder="Password" onChange={e => setPass(e.target.value)} />
            				<button>Sign In</button>
            		</form>
            	</div>
            </div>
           <Buttons/>
			</div>
        </>
    );
}

export default Login;
