import "./style.css"
const animatedBackground = () =>{
    return (
        <div id="background">
            <div className="d-flex flex-column justify-content-center w-100 h-100">

            	<div className="d-flex flex-column justify-content-center align-items-center">

            	</div>
            </div>
        </div>
    )
}
export default animatedBackground;
